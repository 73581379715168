import React, { Component } from "react";
import firebase, { UserDB } from "Firebase.js";

// components

export default class GetAdmin extends React.Component {
	state = {reveal: false}
	hehe = () => {
		if (this.state.reveal == false) {
			return(
			<div>
				<button onClick={() => this.setState({ reveal: true })} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
					I am an officer/rep and I would like admin
				</button>
				<button onClick={() => firebase.auth().signOut()} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
					Sign Out
				</button>
			</div>
			)
		}
		else {
			console.log(firebase.auth().currentUser.email)
			return(
				<div>
					Please send the following code to Clint Wang via Discord: <code className="text-yellow-400">{UserDB.getUser(firebase.auth().currentUser.email)}</code>
					<p className="text-red-400 font-xs font-thin ">(If blank, make sure you sign up as a tutor/student at portal.soarlearn.org)</p>
					<p className="text-green-400 font-xs font-thin ">Reload after Clint has verified your admin priveledges</p>
				</div>
			)
		}
	}
	render() {
		return(
			<div className=" p-8 rounded-lg flex justify-center bg-gray-800 text-white shadow-lg">
				{this.hehe()}
			</div>
		)
	}
}