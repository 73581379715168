import firebase from "firebase";
const config = {
  apiKey: "AIzaSyCInDSiCzj1cQWB7h1cfisxLW2nyLZNXqI",
  authDomain: "soar-portal.firebaseapp.com",
  databaseURL: "https://soar-portal-default-rtdb.firebaseio.com",
  projectId: "soar-portal"
  //storageBucket: "soar-portal.appspot.com",
  //messagingSenderId: "328243213522",
  //appId: "1:328243213522:web:b97e143845709f3375290b",
  //measurementId: "G-CQ4PMWBYKC"
};

firebase.initializeApp(config);

const googleProvider = new firebase.auth.GoogleAuthProvider()
const pdb = firebase.database().ref("/projects");
const udb = firebase.database().ref("/users");

class Request {
  getAll() {
    return pdb;
  }

  create(user) {
    return pdb.push(user);
  }

  update(key, value) {
    return pdb.child(key).update(value);
  }

  delete(key) {
    return pdb.child(key).remove();
  }

  deleteAll() {
    return pdb.remove();
  }
  
  getDone() {
	 var list = []
	 pdb.orderByChild("done").equalTo(true).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push([snapshot.key, snapshot.exportVal()]);
	  });
	  var unapproved = []
	  for (var i = 0; i < list.length ; i++) {
		  if (list[i][1].approved !== true) {
			  unapproved.push(list[i])
		  }
	  }
	  return unapproved;
  }
  getContents(id) {
	 var list = []
	 pdb.orderByChild("id").equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.exportVal());
	  });
	  return list;
  }
}

class User {
  getAll() {
    return udb;
  }

  create(user) {
    return udb.push(user);
  }

  update(key, value) {
    return udb.child(key).update(value);
  }

  delete(key) {
    return udb.child(key).remove();
  }

  deleteAll() {
    return udb.remove();
  }
  
  getUser(id) {
	 var list = []
	 udb.orderByChild("id").equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.key);
	  });
	  return list;
  }
  getContents(id) {
	 var list = []
	 udb.orderByChild("id").equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.exportVal());
	  });
	  return list;
  }
}


export default firebase

export const signInWithGoogle = () => {
  firebase.auth().signInWithPopup(googleProvider).then((res) => {
    console.log("User data: " + res.user)
  }).catch((error) => {
    console.log(error.message)
  })
}
export const RequestDB = new Request();
export const UserDB = new User();