import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import './App.css';
import logo from './logo.svg';


import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import GetAdmin from "views/getadmin.js"


import Home from "views/admin/Home.js";
import Login from "views/login.js"

import firebase, { UserDB } from "Firebase.js"
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}
function App(){
	const [signedIn, setSignedIn] = useState(false)
	const forceUpdate = useForceUpdate();
	if (signedIn) {
		console.log(UserDB.getContents(firebase.auth().currentUser.email))
		if (UserDB.getContents(firebase.auth().currentUser.email)[0] == undefined) {
			return(
			<div className="h-screen bg-gray-900 p-8" >
			<div className=" p-8 bg-gray-800 text-red-400 font-thin rounded-lg flex justify-center shadow-lg">
				Please sign up on the SOAR Portal as a tutor or student before continuing. Your email: <code className="text-yellow-400">{firebase.auth().currentUser.email}</code>
				<button onClick={() => firebase.auth().signOut()} className="m-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
					Sign Out
				</button>
				<p className="text-green-400">Click Refresh Page if you are already registered. This happens when Firebase loads slower than React Render</p>
				<button onClick={forceUpdate} className="m-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
					Refresh Page
				</button>
			</div>
			</div>
			)
		}
		else {
			if (UserDB.getContents(firebase.auth().currentUser.email)[0].admin == true) {
				return(
				<>
					<Sidebar />
					<div className="relative md:ml-64 bg-gray-700 ">
						<AdminNavbar setSignedIn={setSignedIn}/>
						<div className="relative bg-gray-800 md:pt-10 pb-10 pt-0">
							<div className="px-4 md:px-10 mx-auto w-full">
								<div className="flex flex-wrap">
								</div>
							</div>
						</div>
						<div className="px-4 md:px-10 mx-auto w-full md:pt-10">
							  <Switch>
								<Route path="/home" exact component={Home} />
								<Redirect from="*" to="/home" />
							  </Switch>
							  <FooterAdmin />
						</div>
					</div>
				</>
				);
			}
			else {
				console.log("Not Admin")
				return(
				<>
					<div className="h-screen p-4 bg-gray-900">
					<GetAdmin />
					</div>
				</>
				);
			}
		}
	}
	else {
		return(<>
			<Login signedIn={signedIn} setSignedIn={setSignedIn} />
			</>)
	}
}

export default App;

