import React, { Component } from "react";
import firebase, {RequestDB} from "Firebase.js";

// components

export default class Home extends React.Component {
	constructor(props) {
        super(props);
        this.state = {focused: [undefined, undefined], projects:[]}
    }
	/*projectlist = () => {
		console.log(this.state.focused)
		var finishedProjs = RequestDB.getDone()
		console.log(finishedProjs)
		var returnlist = []
		const projectlist = finishedProjs.map((proj, i) => (
			<div className={"p-2 text-gray-200 cursor-pointer " + (finishedProjs[i][0] == this.state.focused[0]
							? "bg-blue-800 hover:bg-blue-700"
							: "bg-gray-800 hover:bg-gray-700")}
			onClick={() => this.setState({focused: finishedProjs[i]})}>
				{finishedProjs[i][1].Title}
				<div className="p-2 font-thin text-gray-300">
				{finishedProjs[i][1].Description}
				</div>
			</div>
		))
		for (var i = 0; i < finishedProjs.length; i++) {
			console.log(finishedProjs[i][0]==this.state.focused[0])
		}
		return(
			<div>
			{projectlist}
			</div>
		)
	}*/

	projectlist = () => {
		const t = this;
		var tempProjects = this.state.projects;
		firebase.database().ref('users').orderByChild('projects').startAt(1).once('value')
			.then(snapshot => {
				
				var finishedProjs = Object.values(snapshot.val())
				console.log(finishedProjs)
				var ids = Object.keys(snapshot.val())
				var tempProjs = finishedProjs.map(function(A) {return A.projects;})
				console.log(tempProjs);
				var finishedProjs = [];
				tempProjs.forEach((usr, i) => {
					console.log(usr);
					for (let proj in usr){
						if (usr[proj].done == true && usr[proj].approved !== true){
							finishedProjs.push([ids[i]+"/projects/"+proj, usr[proj]]);
						}
						
					}
				});
				console.log(finishedProjs[0]);
				const projectlist = finishedProjs.map((proj, i) => (
					<div className={"p-2 text-gray-200 cursor-pointer " + (finishedProjs[i] == this.state.focused
									? "bg-blue-800 hover:bg-blue-700"
									: "bg-gray-800 hover:bg-gray-700")}
					onClick={() => this.setState({focused: finishedProjs[i]})}>
						{finishedProjs[i][1].Title}
						<div className="p-2 font-thin text-gray-300">
						{finishedProjs[i][1].Description}
						</div>
					</div>
				))
				for (var i = 0; i < finishedProjs.length; i++) {
					console.log(finishedProjs[i]==this.state.focused)
				}
				console.log(projectlist)
				t.setState({projectList:projectlist})
				console.log(t.state);
				
			})
			.catch(error => console.log(error));
	}
	onclickA = () => {
		firebase.database().ref('users/'+this.state.focused[0]+'/').update({approved: true}, (error) => {
			if (error) {
			  // The write failed...
			  console.log(error)
			} else {
			  // Data saved successfully!
			  console.log("success")
			}
		  })
		this.componentDidMount();
	}
	onclickB = () => {
		firebase.database().ref('users/'+this.state.focused[0]).update({done: false})
		this.componentDidMount()
	}
	componentDidMount(){
		this.projectlist();
	}
	displayDetails = () => {
		console.log("FOCUSED: " + this.state.focused)
		if (this.state.focused[1] !== undefined) {
			console.log("NOT UNDEFINED!!")
			return(
				<form onSubmit={this.handleSubmit}>
							<div className=" px-2 md:grid md:grid-cols-2 md:gap-3">
								<div className="text-gray-300 grid grid-cols-3 gap-3 col-span-1">
									<div className="col-span-2 sm:col-span-2">
										<label for="title" className="block text-sm font-medium leading-5 text-white">Title</label>
										<input disabled value={this.state.focused[1].Title} id="title" name="title" className=" bg-gray-800 mt-1 form-input block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-1">
										<label for="type" className="block text-sm font-medium leading-5 text-white">Type</label>
										<input disabled list="types" defaultValue={this.state.focused[1].Type} id="type" name="type" className="bg-gray-800 mt-1 form-input block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
										<datalist id="types">
											<option value="Visual">Visual</option>
											<option value="Video">Video</option>
											<option value="Practice">Practice</option>
										</datalist>
									</div>
									<div className="col-span-1 sm:col-span-1">
										<label for="subject" className="block text-sm font-medium leading-5 text-white">Subject</label>
										<input disabled value={this.state.focused[1].Subject} id="subject" name="subject" className="bg-gray-800 mt-1 form-input block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-2 sm:col-span-2">
										<label for="unit" className="block text-sm font-medium leading-5 text-white">Unit/Topic</label>
										<input disabled value={this.state.focused[1].Unit} id="unit" name="unit" className="bg-gray-800 mt-1 form-input block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-3 sm:col-span-3">
										<label for="link" className="block text-sm font-medium leading-5 text-white">Google Drive Link</label>
										<input disabled value={this.state.focused[1].Link} id="link" name="link" className="bg-gray-800 mt-1 form-input block w-full py-2 px-3 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
								<div className="col-span-1">
									<label for="description" className="block text-sm font-medium leading-5 text-white">Description</label>
									<textarea disabled value={this.state.focused[1].Owner +"\n"+this.state.focused[1].Description} id="description" name="description" className="bg-gray-800 mt-1 form-input block w-full py-2 px-3 h-48 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</textarea>
								</div>
							</div>
							<div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
								<button type="button" onClick={() => this.onclickB()}className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
								Reject
								</button>
								<button type="button" onClick={() => this.onclickA()}className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
								Approve
								</button>
							</div>
				</form>
			)
		}
		else {
			return(<div></div>)
		}
	}
	render() {
		console.log(this.state.projectList);
		return (
			<>
			  <div className="flex flex-wrap">
				<div className="w-full px-4">
					<div className="w-full mx-auto bg-gray-900 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
						<div class="p-2">
						<div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
							SOAR Plus Project Approval
						</div>
						<p class="text-white font-thin text-center">
							Please review the following projects and approve or return them. 
						</p>
						</div>
					</div>
					<button onClick={() => this.componentDidMount()}className=" mt-4 ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
						Refresh
					</button>
					<div className="flex pt-4 rounded-xl shadow border border-gray-100 bg-gray-900 m-8 p-4 text-white h-96">
						<div className="w-1/3 border-r-2 border-gray-100 flex flex-col overflow-auto h-full">
							<div className="px-4 py-2 bg-gray-600 text-white font-thin">
								Submitted Projects
							</div>
							{this.state.projectList}
						</div>
						<div className="w-2/3 flex flex-col">
							{this.displayDetails()}
						</div>
					</div>
				</div>
			  </div>
			</>
		  );
	}
  
}