import './assets/styles/main.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

ReactDOM.render(
	  <BrowserRouter>
		<Switch>
		  {/* add routes with layouts */}
		  <Route path="/" component={App} />
		  {/* add redirect for first page */}
		  <Redirect from="*" to="/" />
		</Switch>
	  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();